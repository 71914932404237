import React from 'react';
import About from './About';
function Home() {
    return (
        <>
            <About />
        </>
        
    );
}

export default Home;